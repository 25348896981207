// Color Variables
$primary-color: #008dec;
$text-color: #4d546f;
$text-color-dark: #091337;
$body-color: #fff;
$gray:#f2f3f5;
$dark: #212329;
$black: #000;
$white: #fff;

$primary-gradient: linear-gradient( 25deg, rgb(23,255,211) 0%, rgb(211,252,113) 95%);
$primary-gradient-alt: linear-gradient( 205deg, rgb(23,255,211) 0%, rgb(211,252,113) 95%);
$secondary-gradient: linear-gradient( 6deg, rgb(23,255,211) 0%, rgb(35,227,238) 100%);
$secondary-gradient-alt: linear-gradient( 186deg, rgb(23,255,211) 0%, rgb(35,227,238) 100%);
$primary-shadow: 0px 35px 46px 0px rgba(172, 189, 199, 0.28);

// Font Variables
$primary-font: 'Poppins', sans-serif;
$icon-font: 'themify';
