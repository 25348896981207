.navbar-light .navbar-nav .nav-link {
  color: $text-color-dark;
}

.navigation {
  transition: .3s ease;
}

.navbar {
  transition: .3s ease;
  &-collapse{
    @include desktop {
      padding-bottom: 20px;
    }
  }
}

.nav-bg {
  background-color: $white;
  box-shadow: 0px 10px 20px 0px rgba(0, 141, 236, 0.10);

  .navbar {
    padding: 10px 0;
  }
}

.nav-item {
  padding: 20px 10px;
  @include desktop {
    padding: 0;
  }
}